import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  standalone: true,
  selector: 'portal-icon-warning',
  templateUrl: './icon-warning.component.html',
  styleUrls: ['./icon-warning.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IconWarningComponent {}
