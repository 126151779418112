<div class="portal-flex-container-full error-container">
  <portal-icon-warning class="bg-image"></portal-icon-warning>
  <portal-modal>
    <ng-content title
                select="[title]"></ng-content>
    <ng-content description
                select="[description]"></ng-content>

    <ng-content action
                select="[action]"></ng-content>

    <ng-content footer
                select="[footer]"></ng-content>
  </portal-modal>
  <div class="brand-name-image bottom-right"></div>
</div>
