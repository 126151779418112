import { Component } from '@angular/core';
import { IconWarningComponent } from '../icon-warning/icon-warning.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  standalone: true,
  selector: 'portal-error-wrapper',
  templateUrl: './error-wrapper.component.html',
  styleUrls: ['./error-wrapper.component.scss'],
  imports: [ModalComponent, IconWarningComponent]
})
export class ErrorWrapperComponent {}
